import { configureStore } from "@reduxjs/toolkit";
import collectionReducer from "./redux/reducers/collectionReducer";
import airdropReducer from "./redux/reducers/airdropReducer";
import premintReducer from "./redux/reducers/premintReducer";
import paymentReducer from "./redux/reducers/paymentReducer";
// import magicLinkReducer from "./redux/reducers/magicLinkReducer";
import accessReducer from "./redux/reducers/accessReducer";

const store = configureStore({
  reducer: {
    collection: collectionReducer,
    airdrop: airdropReducer,
    premint: premintReducer,
    payment: paymentReducer,
    // magic: magicLinkReducer,
    access: accessReducer
  },
});

export default store