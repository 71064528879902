import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRoute = () => {
  const auth = Cookies.get('walletAddress');

  return (
    <div>
      <>{auth ? <Outlet /> : <Navigate replace to='/' />}</>
    </div>
  );
};

export default ProtectedRoute;
