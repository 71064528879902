import axios from 'axios';
import { BACKEND_API_URL } from './enviroment';

export const postRequest = (url, data) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem('oracleAuth')
        ? `Bearer ${localStorage.getItem('oracleAuth')}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };
  return axios
    .post(`${BACKEND_API_URL}${url}`, data, config)
    .then((res) => res.data);
};

export const getRequest = (url, data) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem('oracleAuth')
        ? `Bearer ${localStorage.getItem('oracleAuth')}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };
  return axios.get(`${BACKEND_API_URL}${url}`, config).then((res) => res.data);
};
