import { createSlice } from "@reduxjs/toolkit";
import {getAirdropCollection, verifyToAddress, redeem, updateIsSoldTranHashOwnerAddress, getRedeemedAssetByCollectionId } from "../actions/airdropActions";

const airdropSlice = createSlice({
  name: "airdrop",
  initialState: {
    isLoading: false,
    message: null,
    isError: false,
    isSuccess: false,
    airdropData: [],
    totalCount: null,
    modalState: {},
    verifyToAddressDetails: null,
    statusToAddressDetails: null,
    status: {value: '', message: ''},
    redeemIsLoading: false,
    redeemStatusMessage: null,
    redeemSuccess: false,
    redeemError: false,
    redeemedAssets: [],
    // isSoldSuccess: false,
    pagination: {page:1, limit: 6},

    isLoadingRedeemAsset: false,
  },
  reducers: {
    setModalVisible: (state, action) => {
      state.modalState = action.payload;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = null;
      state.redeemSuccess = false;
      state.redeemError = false;
      state.redeemStatusMessage = null;
      // state.isSoldSuccess = false;
      // state.verifyToAddressDetails = null;
      // state.statusToAddressDetails = null;
      state.status = {value: '', message: ''}
    },
    verifySuccess: (state, action) => {
      state.isError = false
    },
    verifyfailed: (state, action) => {
      state.isSuccess = false;
      state.verifyToAddressDetails = null;
      state.statusToAddressDetails = null;
    },
    setVerifyStatus: (state, action) => {
      // console.log(action.payload)
      state.status = action.payload
    },
    setState: (state, action) => {
      // console.log(action)
      state.redeemIsLoading = action.payload.isLoading;
      state.redeemSuccess = action.payload.success;
      state.redeemError = action.payload.error;
      state.redeemStatusMessage = action.payload.message
    },
    setPagination: (state, action) => {
      // console.log(action)
      state.pagination = action.payload
    }

  },
  extraReducers: (builder) => {
    builder
    .addCase(getAirdropCollection.pending, (state, action) => {
      state.isLoading = true;
    }).addCase(getAirdropCollection.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.isSuccess = true
      state.airdropData = action.payload.data
      state.totalCount = action.payload.totalCount
      // console.log('ss')
    }).addCase(getAirdropCollection.rejected, (state,action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    .addCase(verifyToAddress.pending, (state, action) => {
      state.isLoading = true;
    }).addCase(verifyToAddress.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true
      state.verifyToAddressDetails = action.payload.data
      state.statusToAddressDetails = action.payload.data.verifyClaimStatus
    }).addCase(verifyToAddress.rejected, (state,action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    .addCase(redeem.pending, (state, action) => {
      state.redeemIsLoading = true;
    }).addCase(redeem.fulfilled, (state, action) => {
      state.redeemIsLoading = false;
      state.redeemSuccess = true
    }).addCase(redeem.rejected, (state,action) => {
      state.redeemIsLoading = false
      state.redeemError = true
      // state.isSuccess = false
      // state.statusToAddressDetails = null
      state.redeemStatusMessage = action.payload
    })
    .addCase(updateIsSoldTranHashOwnerAddress.pending, (state, action) => {
      state.isLoading = true;
    })
    .addCase(updateIsSoldTranHashOwnerAddress.fulfilled, (state, action) => {
      // console.log(action.payload)
      state.isLoading = false;
      // state.isSoldSuccess = true;
      // state.isMessage = action.payload
      // state.isVisible = { type: 'claim', visible: true }
      // state.paymentStatus = action.payload
    })
    .addCase(updateIsSoldTranHashOwnerAddress.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      // state.isVisible = { type: 'claim', visible: true }
    })
    .addCase(getRedeemedAssetByCollectionId.pending, (state) => {
      state.isLoadingRedeemAsset = true;
    })
    .addCase(getRedeemedAssetByCollectionId.fulfilled, (state, action) => {
      state.isLoadingRedeemAsset = false;
      // state.isSuccess = true;
      state.redeemedAssets = action.payload.data;
    })
    .addCase(getRedeemedAssetByCollectionId.rejected, (state, action) => {
      state.isLoadingRedeemAsset = false;
      state.isError = true;
      state.message = action.payload;
    })
  }
});

export const { setModalVisible, reset, setVerifyStatus, verifyfailed, verifySuccess, setState, setPagination} = airdropSlice.actions;
export default airdropSlice.reducer;
