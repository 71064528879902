import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Loader from "../Spin/Loader";

const Access = () => {
  const { isLoading } = useSelector((state) => state.access);
console.log(isLoading)
  return (
    <div>
      <>{isLoading ? <Loader /> : <Outlet />}</>
    </div>
  );
};

export default Access;
