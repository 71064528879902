import { createSlice } from "@reduxjs/toolkit";
import {
  getFiatPaymentEnableOrDisable,
  verifyOtp,
  sendOtp,
  getPaymentStatus,
  sendOtpToClaimToken,
  verifyOtpAndClaimToken,
  updateIsSoldTranHashOwnerAddress,
  updatePaymentIdOnSuccessPage,
  buyPremintAssest,
  buyPremintAssestERC1155,
  getAssetAmountById,
  iFrameRequestPayment,
} from "../actions/paymentActions";

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    isLoading: false,
    isMessage: null,
    isError: false,
    isSuccess: false,
    isVisible: {},
    data: null,
    paymentLinkDetails: null,
    formState: 1,
    formData: null,
    isOtpSuccess: false,
    isOtpError: false,
    isOtpMessage: null,
    paymentStatus: null,
    isFiatPaymentEnableData: [],
    mintSuccess: false,
    value: 0,
    noOfCopies: 1,
    amount: "",
    isIframePaymentSuccess: false
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.isMessage = null;
      state.isOtpSuccess = false;
      state.isOtpError = false;
      state.isOtpMessage = false;
      state.mintSuccess = false;
      state.value = 0
      state.isIframePaymentSuccess = false
    },
    setFormState: (state, action) => {
      state.formState = action.payload;
    },
    setFormData: (state, action) => {
      // console.log(action.payload);
      state.formData = action.payload;
    },
    setValue: (state, action) => {
      // console.log(action.payload);
      state.value = action.payload;
    },
    setModalVisibleState(state, action) {
      // console.log(action.payload);
      state.isVisible = action.payload;
      state.data = action.payload.data;
      state.formState = 1
    },
    setNoOfCopiesValue(state, action) {
      state.noOfCopies = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // .addCase(getFiatPaymentEnableOrDisable.pending, (state, action) => {
      //   state.isLoading = true;
      // })
      .addCase(getFiatPaymentEnableOrDisable.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.isSuccess = true;
        state.isFiatPaymentEnableData = action.payload;
        // state.isMessage = action.payload
      })
      // .addCase(getFiatPaymentEnableOrDisable.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.isError = true;
      //   state.isMessage = action.payload;
      // })
      .addCase(sendOtp.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.isLoading = false;
        state.isSuccess = true;
        state.isMessage = action.payload;
        // state.formState = 7;
        // state.isVisible = { type: "payment", visible: true };
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isMessage = action.payload;
      })
      .addCase(verifyOtp.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isOtpSuccess = true;
        state.isOtpMessage = "OTP verified";
        state.paymentLinkDetails = action.payload;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isOtpError = true;
        state.isOtpMessage = action.payload;
      })
      .addCase(getAssetAmountById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAssetAmountById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.amount = action.payload;
      })
      .addCase(getAssetAmountById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true
      })
      .addCase(iFrameRequestPayment.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(iFrameRequestPayment.fulfilled, (state, action) => {
        console.log(action.payload)
        state.isLoading = false;
        state.isIframePaymentSuccess = true;
        state.isMessage = action.payload
      })
      .addCase(iFrameRequestPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true
      })
      .addCase(getPaymentStatus.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPaymentStatus.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.isLoading = false;
        state.isSuccess = true;
        state.paymentStatus = action.payload;
      })
      .addCase(getPaymentStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isMessage = action.payload;
      })
      .addCase(sendOtpToClaimToken.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(sendOtpToClaimToken.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.isLoading = false;
        state.isSuccess = true;
        state.isMessage = action.payload;
        state.isVisible = { type: "claim", visible: true };
        // state.paymentStatus = action.payload
      })
      .addCase(sendOtpToClaimToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isMessage = action.payload;
        state.isVisible = { type: "claim", visible: true };
      })
      .addCase(verifyOtpAndClaimToken.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(verifyOtpAndClaimToken.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.isLoading = false;
        state.isSuccess = true;
        state.isMessage = 'Token Claimed Successfully';
        state.mintSuccess = true;
        // state.isVisible = { type: "claim", visible: false };
        // state.paymentStatus = action.payload
      })
      .addCase(verifyOtpAndClaimToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isMessage = action.payload;
        // state.isVisible = { type: "claim", visible: true };
      })
      .addCase(updateIsSoldTranHashOwnerAddress.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateIsSoldTranHashOwnerAddress.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.isLoading = false;
        // state.isSuccess = true;
        // state.isMessage = action.payload
        // state.isVisible = { type: 'claim', visible: true }
        // state.paymentStatus = action.payload
      })
      .addCase(updateIsSoldTranHashOwnerAddress.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isMessage = action.payload;
        // state.isVisible = { type: 'claim', visible: true }
      })
      .addCase(updatePaymentIdOnSuccessPage.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updatePaymentIdOnSuccessPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updatePaymentIdOnSuccessPage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isMessage = action.payload;
        // state.isVisible = { type: 'claim', visible: true }
      })
      .addCase(buyPremintAssest.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(buyPremintAssest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isMessage = "Token Minted Successfully"
        // state.formState = 0
      })
      .addCase(buyPremintAssest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isMessage = action.payload;
        // state.formState = 0
        // console.log(action)
        // state.isVisible = { type: 'claim', visible: false }
      })
      .addCase(buyPremintAssestERC1155.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(buyPremintAssestERC1155.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isMessage = "Token Minted Successfully"
        // state.formState = 0
      })
      .addCase(buyPremintAssestERC1155.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isMessage = action.payload;
        // state.formState = 0
        // console.log(action)
        // state.isVisible = { type: 'claim', visible: false }
      });
  },
});

export const { reset, setFormState, setFormData, setValue, setModalVisibleState, setNoOfCopiesValue } =
  paymentSlice.actions;
export default paymentSlice.reducer;
