import axios from "axios";
import { BACKEND_API_URL } from "../utils/enviroment";
import { getRequest, postRequest } from "../utils/request";
const URI = "api";

const getUniqueCollectionAPI = () => getRequest(`/${URI}/frontend/getUniqueCollectionToShow`)

const getPremintAssetByIdAPI = (id) => getRequest(`/${URI}/frontend/preMintAsset/${id}`)






const getCollectionAPI = () =>
  axios
    .get(`${BACKEND_API_URL}/${URI}/admin/allCollection`)
    .then((res) => res.data);

// const getAirdropCollectionAPI = () =>
//   axios
//     .get(`${BACKEND_API_URL}/${URI}/admin/allCollection`)
//     .then((res) => res.data);




// const updateTransactionHashAPI = (data) =>
//   axios
//     .post(`${BACKEND_API_URL}/${URI}/admin/updateTransactionHash`, data)
//     .then((res) => res.data);

// const getUpdateTransactionHashAPI = (id) =>
//   axios
//     .get(`${BACKEND_API_URL}/${URI}/admin/getTransactionStatus/${id}`)
//     .then((res) => res.data);

const getSmartContractAddressAPI = (data) =>
  axios
    .get(
      `${BACKEND_API_URL}/${URI}/admin/getSmartContractAddress/${data?.registrationType}/${data?.blockchainType}/${data?.networkType}/${data?.smartContractType}`
    )
    .then((res) => res.data);

// const updateOwnerAddressAPI = (data) =>
//   axios
//     .post(`${BACKEND_API_URL}/${URI}/admin/updateAssetOwner`, data)
//     .then((res) => res.data);

// const updateIsSoldStatusAPI = data => postRequest(`/${URI}/admin/updateIsSold`, data)

const updateIsSoldTranHashOwnerAddressAPI = data => postRequest(`/${URI}/admin/updateIsSoldTranHashOwnerAddress`, data)


const verifyUsingToAddressAPI = (data) =>
  axios
    .post(`${BACKEND_API_URL}/${URI}/admin/verifyToAddress`, data)
    .then((res) => res.data);

const getCollectionSmartContractAPI = ({
  collection_id,
  collectionRegistrationType,
  blockchainType,
  networkType,
  smartContractType,
}) =>
  axios
    .get(
      `${BACKEND_API_URL}/${URI}/admin/getUniqueCollSmartContract/${collection_id}/${collectionRegistrationType}/${blockchainType}/${networkType}/${smartContractType}`
    )
    .then((res) => res.data);



export {
  getUniqueCollectionAPI,
  getCollectionAPI,
  // getAirdropCollectionAPI,
  getPremintAssetByIdAPI,
  // updateTransactionHashAPI,
  // getUpdateTransactionHashAPI,
  getSmartContractAddressAPI,
  // updateOwnerAddressAPI,
  // updateIsSoldStatusAPI,
  verifyUsingToAddressAPI,
  getCollectionSmartContractAPI,
  updateIsSoldTranHashOwnerAddressAPI
};
