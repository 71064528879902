import { Route, Routes } from "react-router-dom";
import { Home, Detail, OwnedAsset, Checkout, NotFound } from "./pages";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAccess } from "./redux/actions/acessActions";
import { reset } from "./redux/reducers/accessReducer";
import Access from "./utils/Accsess";
import ProtectedRoute from "./utils/ProtectedRoutes";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("oracleAuth")) {
      dispatch(
        getAccess({
          frontend_domain_url:
            window.location.origin === "http://localhost:3000"
              ? "https://oracle.liveplex.io"
              : window.location.origin,
        })
      );
    }else{
      dispatch(reset())
    }
    return () => dispatch(reset());
  }, [dispatch]);

  return (
    <>
      <Routes>
        <Route element={<Access />}>
          <Route path="/" element={<Home />} />
          <Route path="/detail/:id" element={<Detail />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/owned-assets" element={<OwnedAsset />} />
          </Route>
          <Route path="/checkout/:id" element={<Checkout />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
