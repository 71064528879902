import Web3 from "web3";
import { INFURA_MAINNET_URL } from "./enviroment";

export const checkMintToken = async (transactionHash) => {

    const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_MAINNET_URL));
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const timer = setInterval(() => {
          web3.eth.getTransactionReceipt(transactionHash).then((res) => {
            console.log("gettrnx", res);
            if (res?.status) {
              resolve(res);
              clearInterval(timer);
            }
          });
        }, 3000);
      }, 3000);
    });
  };