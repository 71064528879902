import React from "react"
// import Home from "./Home";
// import Detail from "./Detail";
// import OwnedAsset from "./OwnedAsset";
// import Checkout from "./Checkout";

const Home = React.lazy(() => import(/* webpackChunkName: "Home" */ './Home'))
const Detail = React.lazy(() => import(/* webpackChunkName: "Detail" */ './Detail'))
const OwnedAsset = React.lazy(() => import(/* webpackChunkName: "OwnedAsset" */ './OwnedAsset'))
const Checkout = React.lazy(() => import(/* webpackChunkName: "Checkout" */ './Checkout'))
const NotFound = React.lazy(() => import(/* webpackChunkName: "Checkout" */ './NotFound'))


export { Home, Detail, OwnedAsset, Checkout, NotFound };
