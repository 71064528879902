import { createSlice } from "@reduxjs/toolkit";
import { getPremintAsset, getPremintAssetCollectionNameAndBioByCollectionId, getPremintAssetByCollectionID, OwnedAsset } from "../actions/premintActions";

const premintSlice = createSlice({
  name: "premint",
  initialState: {
    isLoading: true,
    isSuccess: false,
    isError: false,
    premintAssetData: [],
    CollectionAndBio: null,
    premintAssestByCollectionID: [],
    totalCount: null,
    formState: true,
    ownedAssetData: []
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = null;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getPremintAsset.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPremintAsset.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.premintAssetData = action.payload.data;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(getPremintAsset.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(OwnedAsset.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(OwnedAsset.fulfilled, (state, action) => {
        console.log(action.payload)
        state.isLoading = false;
        state.isSuccess = true;
        state.ownedAssetData = action.payload?.data;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(OwnedAsset.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPremintAssetByCollectionID.pending, (state) => {
        state.isLoading = true;
      })
     .addCase(getPremintAssetCollectionNameAndBioByCollectionId.pending, (state) => {
        // state.isPremintAssetLoading = true;
      })
      .addCase(getPremintAssetCollectionNameAndBioByCollectionId.fulfilled, (state, action) => {
        // state.isPremintAssetLoading = false;
        state.CollectionAndBio = action.payload;
        state.isSuccess = true;
      })
      .addCase(getPremintAssetCollectionNameAndBioByCollectionId.rejected, (state, action) => {
        // state.isPremintAssetLoading = false;
        state.message = action.payload;
        state.isError = true;
      })
      .addCase(getPremintAssetByCollectionID.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.premintAssestByCollectionID = action.payload.data;
        state.totalCount = action.payload.totalCount
      })
      .addCase(getPremintAssetByCollectionID.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.isError = true;
      })
  },
});

export const { reset } = premintSlice.actions;
export default premintSlice.reducer;
