import { createAsyncThunk } from "@reduxjs/toolkit";
import  {checkIsFaitPaymentIsEnableAPI, sendOtpAPI, getHostedPaymentAPI, assetAmountByIdAPI,iFrameRequestPaymentAPI, 
        getPaymentStatusAPI, sendOtpToClaimTokenAPI,
        verifyOtpAndClaimTokenAPI, updateIsSoldTranHashOwnerAddressAPI, updatePaymentIdOnSuccessPageAPI, getSmartContractForCryptoAPI } from "../../service/paymentAPI"
import { openNotificationWithIcon } from "../../utils/notification"
import {setModalVisibleState} from "../reducers/paymentReducer"
import { MagicWeb3 } from "../../utils/magicConnect";
import { checkMintToken } from "../../utils/checkRecipt";
import { getPremintAssestById } from "./collectionActions";

export const getFiatPaymentEnableOrDisable = createAsyncThunk("payment/fiatPaymentIsEnable", async (_, thunkAPI) => {
    try {
        const res = await checkIsFaitPaymentIsEnableAPI()
        // console.log(res.data)
        return res.data
    } catch (error) {
        const message = error.response.data.error || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const sendOtp = createAsyncThunk("send/sendOtp", async(data, thunkAPI) => {
    try {
        const res = await sendOtpAPI(data)
        console.log(res)
        return res.message 
    } catch (error) {
        const message = error.response.data.error || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}) 

// <-------------------------------------------------frame------------------------------------------------------>

// export const iFrameRequestPaymentInfo = createAsyncThunk("send/iFrameRequestPaymentInfo", async(data, thunkAPI) => {
//     try {
//         const res = await iFrameRequestPaymentInfo(data)
//         console.log(res)
//         return res.message 
//     } catch (error) {
//         const message = error.response.data.error || error.message || error.toString()
//         return thunkAPI.rejectWithValue(message)
//     }
// }) 

export const verifyOtp = createAsyncThunk("verify/verifyOtp", async(data, thunkAPI) => {
    try {
        const link = await getHostedPaymentAPI(data)
        return link?.hosted_href
    } catch (error) {
        const message = error.response.data.error || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}) 

export const getAssetAmountById = createAsyncThunk("AssetAmountById/getAssetAmountById", async(id, thunkAPI) => {
    try {
        const res = await assetAmountByIdAPI(id)
        // console.log(res)
        return res.amount
    } catch (error) {
        const message = error.response.data.error || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}) 

export const iFrameRequestPayment = createAsyncThunk("iFrameRequestPayment/postiFrameRequestPayment", async(data, thunkAPI) => {
    try {
        const res = await iFrameRequestPaymentAPI(data)
        // console.log(res)
        return res.message
    } catch (error) {
        const message = error.response.data.error || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}) 

// getting payment on succees checkout page
export const getPaymentStatus = createAsyncThunk("payment/getPaymentStatus", async(id, thunkAPI) => {
    try {
        // console.log(id)
        const res = await getPaymentStatusAPI(id)
        return res?.message
    } catch (error) {
        const message = error.response.data.error || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}) 

// getting payment on succees checkout page
export const updatePaymentIdOnSuccessPage = createAsyncThunk("payment/updatePaymentIdOnSuccessPage", async(id, thunkAPI) => {
    try {
        // console.log(id)
        const res = await updatePaymentIdOnSuccessPageAPI(id)
        return res
    } catch (error) {
        const message = error.response.data.error || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}) 

export const sendOtpToClaimToken = createAsyncThunk("otp/sendOtpToClaimTokenAPI", async(data, thunkAPI) => {
    try {
        // console.log(data)
        const res = await sendOtpToClaimTokenAPI(data)
        // console.log(res)
        return res.message
    } catch (error) {
        const message = error.response.data.error || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateIsSoldTranHashOwnerAddress = createAsyncThunk("otp/updateIsSoldTranHashOwnerAddress", async(data, thunkAPI) => {
    try {
        return await updateIsSoldTranHashOwnerAddressAPI(data)
        // const res = await updateIsSoldTranHashOwnerAddressAPI(data)
        // console.log(res)
        // return res
    } catch (error) {
        const message = error.response.data.error || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const verifyOtpAndClaimToken = createAsyncThunk("otp/verifyOtpAndClaimTokenAPI", async(data, {dispatch, rejectWithValue}) => {
    try {
        const result = await verifyOtpAndClaimTokenAPI(data)
        return result
    } catch (error) {
        const message = error.response.data.error || error.message || error.toString()
        return rejectWithValue(message)
    }
})

// export const verifyOtpAndClaimToken = createAsyncThunk("otp/verifyOtpAndClaimTokenAPI", async(data, {dispatch, rejectWithValue}) => {
//     try {
//         const result = await verifyOtpAndClaimTokenAPI(data)
//         // console.log(result)
//         // window.web3 = new Web3(window.ethereum);
//         // window.ethereum.enable();
//         const web3 = new Web3(
//           new Web3.providers.HttpProvider(
//             "https://polygon-mumbai.infura.io/v3/68ad947047714fd49d5d9bff73647d30"
//           )
//         );

//         console.log("trxhash" , result?.mintTokenTransactionHash)
//         const checkMintToken = new Promise((resolve, reject) => {
//           setTimeout(() => {
//             const timer = setInterval(() => {
//                 web3.eth
//                 .getTransactionReceipt(result?.mintTokenTransactionHash)
//                 .then((res) => {
//                   console.log('receipt', res);
//                   // if TransactionReceipt generated and status: true than send TransactionHash details to database
//                   if (res?.status) {
//                     // if res.status: true than update owner address to new owner who buy the assest
//                     dispatch(
//                         updateIsSoldTranHashOwnerAddress({
//                           _id: data._id,
//                           isSold: "sold",
//                           transactionHash: res.transactionHash,
//                           transactionStatus: res.status,
//                           assetOwner: result.wallet_address
//                         })
//                       ).then(() => {
//                         resolve()
//                         clearInterval(timer);
//                       });
//                       // console.log('before resole')
//                       // setTimeout(() => { 
//                         // console.log('wait')
//                       // }, 2000)
//                   }
//                 }).catch((error) => {
//                   const message = error?.response?.data?.error || error?.message || error.toString()
//                   reject(new Error(message))
//                 });
//             }, 3000)
//           }, 2000);
//         }) 
//         return await checkMintToken
//     } catch (error) {
//         const message = error.response.data.error || error.message || error.toString()
//         return rejectWithValue(message)
//     }
// })

export const buyPremintAssest = createAsyncThunk(
  "buyPremintAssest/CyptoBuyPremintAssest",
  async ({ obj, smartcontractDetails }, { dispatch, rejectWithValue }) => {
    try {
      console.log(obj);
      console.log('HELLO...............')
      const {
        walletAddress,
        tokenId,
        uri,
        assetPrice,
        ownerAdress,
        adminAdress,
        adminShareBaisePoint,
        _id,
        collectionId,
      } = obj;
      const web3 = await MagicWeb3();
      const walletBalance = await web3.eth.getBalance(walletAddress);

      // check assest price is greater than walllet balance
      if (Number(assetPrice) > Number(walletBalance)) {
        const message =
          "You do not have enough ETH in your account to pay for the transaction. ";
        openNotificationWithIcon("error", "Failed", message);
        dispatch(setModalVisibleState({ visible: false, data: null }));
        console.log("your balance is low");
        return;
      }
      const res = await getSmartContractForCryptoAPI(smartcontractDetails);
      const webres = await new web3.eth.Contract(
        res.data.abi,
        res.data.address
      );
      console.log(webres.methods);
      console.log(web3);
      // const fee = await fetch("https://gasstation-mumbai.matic.today/v2")
      //   .then((response) => response.json())
      //   .then((json) => json);
      // console.log(fee);
      // console.log(fee?.fast);
      // const gasFee = fee.fast;

      const result = await webres.methods
        .Buypremintasset(
          walletAddress,
          tokenId,
          uri,
          assetPrice,
          ownerAdress,
          adminAdress,
          adminShareBaisePoint
        )
        .send({
          from: walletAddress,
          value: assetPrice,
          gasLimit: 41403,
          gas: 5500000,
          // maxPriorityFeePerGas: Math.ceil(
          //   gasFee?.maxPriorityFee * 1_000_000_000
          // ),
          // maxFeePerGas: Math.ceil(gasFee?.maxFee * 1_000_000_000),
        });
      console.log(result);
       const newdata = checkMintToken(result.transactionHash).then((res) =>
        dispatch(
          updateIsSoldTranHashOwnerAddress({
            _id,
            isSold: "sold",
            transactionHash: res.transactionHash,
            transactionStatus: res.status,
            assetOwner: walletAddress,
          })
        ).then(() => dispatch(getPremintAssestById(collectionId)))
      );
      
      return newdata;
    } catch (error) {
      const message =
        error?.response?.data?.error || error?.message || error.toString();
      return rejectWithValue(message);
    }
  }
);
  
export const buyPremintAssestERC1155 = createAsyncThunk(
  "buyPremintAssest/buyPremintAssestERC1155",
  async ({ obj, smartcontractDetails }, { dispatch, rejectWithValue }) => {
    try {
      console.log(obj);
      console.log('HELLO..............1155.')

      // console.log(smartcontractDetails)
      const {
        walletAddress,
        tokenId,
        uri,
        assetPrice,
        ownerAdress,
        adminAdress,
        adminShareBaisePoint,
        _id,
        collectionId,
        noOfCopies,
        price
      } = obj;
      const web3 = await MagicWeb3();
      const walletBalance = await web3.eth.getBalance(walletAddress);

      // check assest price is greater than walllet balance
      if (Number(assetPrice) > Number(walletBalance)) {
        const message =
          "You do not have enough ETH in your account to pay for the transaction. ";
        openNotificationWithIcon("error", "Failed", message);
        dispatch(setModalVisibleState({ visible: false, data: null }));
        console.log("your balance is low");
        return;
      }
      const res = await getSmartContractForCryptoAPI(smartcontractDetails);
      const webres =  new web3.eth.Contract(
        res.data.abi,
        res.data.address
      );
      console.log(webres.methods);
      console.log(web3);
      // const fee = await fetch("https://gasstation-mumbai.matic.today/v2")
      //   .then((response) => response.json())
      //   .then((json) => json);
      // console.log(fee);
      // console.log(fee?.fast);
      // const gasFee = fee.fast;
      

      const result = await webres.methods
        .Buypremintasset(
          walletAddress,
          tokenId,
          noOfCopies,
          uri,
          price,
          ownerAdress,
          adminAdress,
          adminShareBaisePoint
        )
        .send({
          from: walletAddress,
          value: price,
          gasLimit: 41403,
          gas: 5500000,
          // maxPriorityFeePerGas: Math.ceil(
          //   gasFee?.maxPriorityFee * 1_000_000_000
          // ),
          // maxFeePerGas: Math.ceil(gasFee?.maxFee * 1_000_000_000),
        });
      console.log(result);
       const newdata = checkMintToken(result.transactionHash).then((res) =>
        dispatch(
          updateIsSoldTranHashOwnerAddress({
            _id,
            isSold: "sold",
            transactionHash: res.transactionHash,
            transactionStatus: res.status,
            assetOwner: walletAddress,
          })
        ).then(() => dispatch(getPremintAssestById(collectionId)))
      );

      return newdata;
    } catch (error) {
      const message =
        error?.response?.data?.error || error?.message || error.toString();
      return rejectWithValue(message);
    }
  }
);
