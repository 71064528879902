import { createSlice } from "@reduxjs/toolkit";
import { getAccess, login } from "../actions/acessActions";
import Cookies from "js-cookie";
import { expiresIn } from "../../utils/enviroment";

const accessSlice = createSlice({
  name: "access",
  initialState: {
    isLoading: true,
    isSuccess: false,
    isError: false,
    isVisible: false,
    isLoadingLogin: false,
    open: false,
    walletAddress: Cookies.get('walletAddress'),
    email: Cookies.get('email')
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isLoadingLogin = false
    },
    setModalVisible(state, action) {
      state.isVisible = action.payload;
    },
    handleOpenChange(state, action) {
      state.open = action.payload;
    },
    logout: (state, action) => {
      console.log(action)
      Cookies.remove('email')
      Cookies.remove('walletAddress')
      state.open = false
      action.payload('/')
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccess.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAccess.fulfilled, (state, action) => {
        state.isLoading = false;
        localStorage.setItem("oracleAuth", action.payload.token);
      })
      .addCase(getAccess.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(login.pending, (state) => {
        state.isLoadingLogin = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoadingLogin = false;
        Cookies.set("email", action.payload?.email, { expires: expiresIn });
        Cookies.set("walletAddress", action.payload?.walletAddress, { expires: expiresIn });
        state.isVisible = false
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoadingLogin = false;
      })
  },
});

export const { reset, setModalVisible, handleOpenChange, logout } = accessSlice.actions;
export default accessSlice.reducer;
