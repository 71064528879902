import { postRequest, getRequest } from "../utils/request";
const URI = "api";

export const getAirdropCollectionAPI = ({ type, page, limit }) =>
  getRequest(`/${URI}/frontend/getCollectionByType/${type}/${page}/${limit}`);

export const verifyUsingToAddressAPI = (data) =>
  postRequest(`/${URI}/frontend/verifyToAddress`, data);

export const getCollectionSmartContractAPI = ({
  collection_id,
  collectionRegistrationType,
  blockchainType,
  networkType,
  smartContractType,
}) =>
  getRequest(
    `/${URI}/frontend/getUniqueCollSmartContract/${collection_id}/${collectionRegistrationType}/${blockchainType}/${networkType}/${smartContractType}`
  );

export const updateIsSoldTranHashOwnerAddressAPI = (data) =>
  postRequest(`/${URI}/frontend/updateIsSoldAfterReddemed`, data);

export const getRedeemedAssetByCollectionIdAPI = ({
  collection_id,
  page,
  limit,
}) =>
  getRequest(
    `/${URI}/frontend/airDropAllAssetByCollectionId/${collection_id}/${page}/${limit}`);
