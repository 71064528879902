import { createAsyncThunk } from "@reduxjs/toolkit";
import { accessAPI, loginAPI } from "../../service/accessAPI";
import { openNotificationWithIcon } from "../../utils/notification" 
// import Cookies from "js-cookie";
// import { handleOpenChange } from "../reducers/accessReducer";

export const getAccess = createAsyncThunk(
  "access/getAccess",
  async (data, thunkAPI) => {
    try {
      return await accessAPI(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// export const getInfuraUrl = createAsyncThunk(
//   "infura/getInfuraUrl",
//   async (_, thunkAPI) => {
//     try {
//       const resp = await infuraUrlAPI(chainId)
//       // localStorage.setItem("infuraUrl", resp.url);
//       return resp
//     } catch (error) {
//       const message =
//         (error.response && error.response.data && error.response.data.error) ||
//         error.message ||
//         error.toString();
//       openNotificationWithIcon("error", "Failed", message);
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

export const login = createAsyncThunk(
  "access/login",
  async ({values, navigate}, thunkAPI) => {
    try {
      const res = await loginAPI(values)
      // window.location.href === 'owned-assets' && navigate('/')
      return res
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      openNotificationWithIcon("error", "Failed", message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);



