import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPremintAssetAPI,
  getPremintAssetCollectionNameAndBioByCollectionIdAPI,
  getPremintAssetByCollectionIdAPI,
  getOwnedAssetAPI,
} from "../../service/premintAPI";

export const getPremintAsset = createAsyncThunk(
  "premint/getPremintAsset",
  async (data, thunkAPI) => {
    try {
      const res = await getPremintAssetAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPremintAssetCollectionNameAndBioByCollectionId =
  createAsyncThunk(
    "collectionNameAndBio/premintAssest",
    async (id, thunkAPI) => {
      try {
        return await getPremintAssetCollectionNameAndBioByCollectionIdAPI(id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const getPremintAssetByCollectionID = createAsyncThunk(
  "collection/premintAssest",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      dispatch(getPremintAssetCollectionNameAndBioByCollectionId(data.id));
      return await getPremintAssetByCollectionIdAPI(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

export const OwnedAsset = createAsyncThunk(
  "collection/OwnedAsset",
  async (data, { rejectWithValue }) => {
    try {
      const res = await getOwnedAssetAPI(data);
      return res
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);
