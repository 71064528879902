import { createSlice } from "@reduxjs/toolkit";
import {
  getUniqueCollection,
  getCollection,
  getPremintAssestById,
  
  // buyPremintAssest,
  // updateTransactionHash,
  // GetUpdateTransactionHash,
  // updateOwnerAddress,
} from "../actions/collectionActions";

const collectionSlice = createSlice({
  name: "collection",
  initialState: {
    isLoading: true,
    isPremintAssetLoading: true,
    collection: [],
    uniqueCollection: [],
    premintAsserts: [],
    CollectionAndBio: null,
    premintAssetDetials: [],
    status: null,
    message: null,
    isError: false,
    isSuccess: false,
    isVisible: {},
    data: null,
    isSoldStatus: {},
    address: ''
  },
  reducers: {
    setModalVisibleState(state, action) {
      // console.log(action.payload)
      state.isVisible = action.payload;
      state.data = action.payload.data;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUniqueCollection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUniqueCollection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.uniqueCollection = action.payload.data;
      })
      .addCase(getUniqueCollection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCollection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCollection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.collection = action.payload.data;
        state.isSuccess = true;
      })
      .addCase(getCollection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPremintAssestById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPremintAssestById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.premintAssetDetials = action.payload.data;
        state.address = action.payload.address
      })
      .addCase(getPremintAssestById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // .addCase(buyPremintAssest.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(buyPremintAssest.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.isSuccess = true;
      // })
      // .addCase(buyPremintAssest.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.isError = true;
      //   state.message = action.payload;
      // })
  },
});

export const { setModalVisibleState, reset, setIsSold } = collectionSlice.actions;
export default collectionSlice.reducer;
