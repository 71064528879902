// production
// export const BACKEND_API_URL = "https://backend.daniclosandorra.thenftbrewery.com"

// development 
export const BACKEND_API_URL = "https://dev.backend.drops.thenftbrewery.com"

// production
// export const BACKEND_API_URL = "https://backend.drops.thenftbrewery.com"
export const expiresIn = 1
// export const expiresIn = new Date(Date.now() + 60 * 60 * 1000)

export const magicLinkPublicKey = "pk_live_D77CC2DBEFD3F344"

export const INFURA_MAINNET_URL = "https://polygon-mumbai.infura.io/v3/68ad947047714fd49d5d9bff73647d30"

export const chainId = 80001