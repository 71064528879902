import { getRequest } from "../utils/request";
const URI = "api";

export const getPremintAssetAPI = ({ page, limit }) => getRequest(`/${URI}/frontend/webAllPremintAsset/${page}/${limit}`)

export const getPremintAssetCollectionNameAndBioByCollectionIdAPI = (id) => getRequest(`/${URI}/frontend/getBio/${id}`)

export const getPremintAssetByCollectionIdAPI = ({ id, page, limit }) => getRequest(`/${URI}/frontend/webAppAllPremintAsset/${id}/${page}/${limit}`)

export const getOwnedAssetAPI = ({ walletAdress, page, limit }) => getRequest(`/${URI}/frontend/oracle/ownedAssetOracle/${walletAdress}/${page}/${limit}`)
