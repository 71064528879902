import { getRequest, postRequest } from "../utils/request";
const URI = "api";

export const checkIsFaitPaymentIsEnableAPI = () =>
  getRequest(`/${URI}/frontend/webAppGetFiatPaymentSetting`);

export const sendOtpAPI = (data) =>
  postRequest(`/${URI}/frontend/sendPaymentVerificationOTP`, data);

// export const verifyOtpAPI = (data) => postRequest(`/${URI}/admin/payment/verifyPaymentOTP`, data)

// without iframe --------------------->
// export const getHostedPaymentAPI = (data) =>
//   postRequest(`/${URI}/frontend/CreateHostedPayments`, data);

// iframe gethostedpaymant------------------->

export const getHostedPaymentAPI = (data) =>
  postRequest(`/${URI}/frontend/iFrameRequestPaymentInfo`, data);

export const assetAmountByIdAPI = (id) =>
  getRequest(`/${URI}/frontend/assetAmountById/${id}`);

export const iFrameRequestPaymentAPI = (data) =>
  postRequest(`/${URI}/frontend/iFrameRequestPayment`, data);

// <--------------------------END------------------------------->

export const getPaymentStatusAPI = (id) =>
  getRequest(`/${URI}/admin/payment/GetPayment/${id}`);

export const getTransactionHashAPI = (ids) =>
  postRequest(`/${URI}/admin/payment/mintToken`, ids);

export const sendOtpToClaimTokenAPI = (id) =>
  postRequest(`/${URI}/frontend/sendOTPtoClaimToken`, id);

export const verifyOtpAndClaimTokenAPI = (data) =>
  postRequest(`/${URI}/frontend/claimTokenOracle`, data);

export const updateIsSoldTranHashOwnerAddressAPI = (data) =>
  postRequest(`/${URI}/frontend/updateIsSoldTranHashOwnerAddress`, data);

export const updatePaymentIdOnSuccessPageAPI = (data) =>
  postRequest(`/${URI}/frontend/updatePaymentId`, data);

// <-----------------------------Iframe---------------------------------------------------->

// export const iFrameRequestPaymentInfoAPI = (data) =>
//   postRequest(`/${URI}/frontend/iFrameRequestPaymentInfo`, data);

export const getSmartContractForCryptoAPI = ({
  collection_id,
  blockchainType,
  networkType,
  smartContractType,
}) =>
  getRequest(
    `/${URI}/frontend/getUniqueCollSmartContract/${collection_id}/1/${blockchainType}/${networkType}/${smartContractType}`
  );
// https://dev.backend.drops.thenftbrewery.com/api/admin/collectionSmartContract/getUniqueCollSmartContract/62ecb940eab88ce31f90c203/1/1/1/0
