import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAirdropCollectionAPI,
  verifyUsingToAddressAPI,
  getCollectionSmartContractAPI,
  updateIsSoldTranHashOwnerAddressAPI,
  getRedeemedAssetByCollectionIdAPI
} from "../../service/airdropApi";
import web3 from "web3";

export const getAirdropCollection = createAsyncThunk(
  "airdrop/getAirdropCollection",
  async (data, thunkAPI) => {
    try {
      const res = await getAirdropCollectionAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getRedeemedAssetByCollectionId = createAsyncThunk(
  "airdrop/getRedeemedAssetByCollectionId",
  async (data, thunkAPI) => {
    try {
      const res = await getRedeemedAssetByCollectionIdAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyToAddress = createAsyncThunk(
  "airdrop/verfiyAirdrop",
  async (data, thunkAPI) => {
    // console.log(thunkAPI.getState().airdrop.pagination);
    try {
      return await verifyUsingToAddressAPI(data);
    } catch (error) {
      // console.log(error);
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateIsSoldTranHashOwnerAddress = createAsyncThunk("otp/updateIsSoldTranHashOwnerAddress", async(data, thunkAPI) => {
  try {
      return await updateIsSoldTranHashOwnerAddressAPI(data)
      // const res = await updateIsSoldTranHashOwnerAddressAPI(data)
      // console.log(res)
      // return res
  } catch (error) {
      const message = error.response.data.error || error.message || error.toString()
      return thunkAPI.rejectWithValue(message)
  }
})

export const redeem = createAsyncThunk(
  "airdrop/redeemAirdrop",
  async (
    {
      _id,
      toAddress,
      tokenId,
      uri,
      hexProof,
      collection_id,
      collectionRegistrationType,
      blockchainType,
      networkType,
      smartContractType,
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const data = {
        collection_id,
        collectionRegistrationType,
        blockchainType,
        networkType,
        smartContractType,
      };
      const result = await getCollectionSmartContractAPI(data);
      console.log(result);
      window.web3 = new web3(window.ethereum);
      window.ethereum.enable();

      const fee = await fetch("https://gasstation-mumbai.matic.today/v2")
        .then((response) => response.json())
        .then((json) => json);
      console.log(fee);
      console.log(fee?.fast);
      const gasFee = fee.fast;
      console.log(result.data.abi.abi, result.data.address);
      const webres = await new window.web3.eth.Contract(
        result.data.abi.abi,
        result.data.address
      );
      // console.log(webres.methods);
      // console.log(toAddress, tokenId, uri, hexProof);
      await webres.methods
        .redeem(toAddress, tokenId, uri, hexProof)
        .send({
          from: toAddress,
          maxPriorityFeePerGas: Math.ceil(
            gasFee?.maxPriorityFee * 1_000_000_000
          ),
          maxFeePerGas: Math.ceil(gasFee?.maxFee * 1_000_000_000),
        })
        .on("transactionHash", function (hash) {
          console.log("transactionHash", hash);
          // getting Transaction
          setTimeout(() => {
            const timer = setInterval(() => {
              // checking and waiting for generated TransactionReceipt
              window.web3.eth.getTransactionReceipt(hash).then((res) => {
                console.log("gettrnx", res);
                // if TransactionReceipt generated and status: true than send TransactionHash details to database
                if (res.status) {
                  // return clearInterval(timer);
                  //  return
                  dispatch(
                    updateIsSoldTranHashOwnerAddress({
                      _id,
                      collection_id,
                      transactionHash: res.transactionHash,
                      transactionStatus: res.status,
                    })
                  ).then(() => {
                    dispatch(getRedeemedAssetByCollectionId({...getState().airdrop.pagination, collection_id}))
                    return clearInterval(timer);
                  });
                }
              });
              // .catch((error) => console.log(error));
            }, 2000);
          }, 2000);
          
        });

      // console.log(contract_Methods);

      // return contract_Methods;
    } catch (error) {
      // console.log(error);
      if (
        error.message ===
        "Invalid parameters: must provide an Ethereum address."
      ) {
        const message = "Entered address and wallet address must be same";
        return rejectWithValue(message);
      }
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

// export const redeem = createAsyncThunk(
//   "airdrop/redeemAirdrop",
//   async (
//     {
//       toAddress,
//       tokenId,
//       uri,
//       hexProof,
//       collection_id,
//       collectionRegistrationType,
//       blockchainType,
//       networkType,
//       smartContractType,
//     },
//     { rejectWithValue }
//   ) => {
//     try {
//       const data = {
//         collection_id,
//         collectionRegistrationType,
//         blockchainType,
//         networkType,
//         smartContractType,
//       };
//       window.web3 = new web3(window.ethereum);
//       window.ethereum.enable();
//       getCollectionSmartContractAPI(data)
//         .then((result) =>
//           // console.log("resutlt", result);
//           new window.web3.eth.Contract(
//             result.data.abi.abi,
//             result.data.address
//           )
//         )
//         .then((webres) => {
//           console.log("weebre", webres);
//           webres.methods
//             .redeem(toAddress, tokenId, uri, hexProof)
//             .send({ from: toAddress })
//             .on("transactionHash", function (hash) {
//               // getting Transaction
//               const timer = setInterval(() => {
//                 // checking and waiting for generated TransactionReceipt
//                 window.web3.eth.getTransactionReceipt(hash).then((res) => {
//                   console.log("gettrnx", res);
//                   // if TransactionReceipt generated and status: true than send TransactionHash details to database
//                   if (res.status) {
//                     return clearInterval(timer);
//                     //  return
//                   }
//                 });
//                 // .catch((error) => console.log(error));
//               }, 2000);
//             });
//         })
//       // return contract_Methods;
//     } catch (error) {
//       console.log(error);
//       if (
//         error.message ===
//         "Invalid parameters: must provide an Ethereum address."
//       ) {
//         const message = "Entered address and wallet address must be same";
//         return rejectWithValue(message);
//       }
//       const message =
//         (error.response && error.response.data && error.response.data.error) ||
//         error.message ||
//         error.toString();
//       return rejectWithValue(message);
//     }
//   }
// );
